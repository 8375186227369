import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
import { useAuth } from "../../provider/AuthProvider";

const AuthLayout = () => {
  const { logout } = useAuth();

  // const [isTokenExpired, setIsTokenExpired] = useState(false)
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   if(token) {
  //     const decodedToken = jwtDecode(token);
  //     const isExpired = decodedToken.exp * 1000 < Date.now()
  //     if(isExpired) {
  //       setIsTokenExpired(isExpired)
  //       toast.error('Your Session Expired')
  //       localStorage.clear()
  //     }
  //   }
  // }, [])

  return <>{token ? <Navigate to="/app/dashboard" replace /> : <Outlet />}</>;
};

export default AuthLayout;
