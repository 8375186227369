import { createSlice } from '@reduxjs/toolkit'

export const essaySlice = createSlice({
    name: 'essay',
    initialState: {
        // currentAction: 'create',
        essays: [
            {
                question: '',
                correctAnswer: '',
                score: 10
            }
        ]
    },
    reducers: {
        setEssayQuestion: (state, action) => {
            const {index, value} = action.payload
            state.essays[index].question = value
        },
        setEssayCorrectAnswer: (state, action) => {
            const {index, value} = action.payload
            state.essays[index].correctAnswer = value
        },
        setEssayScore: (state, action) => {
            const {index, value} = action.payload
            state.essays[index].score = parseInt(value)
        },
        setWholeESState: (state, action) => {
            const {data} = action.payload
            const essays = [
                {
                    question: data.question,
                    correctAnswer: data.correctAnswer,
                    score: data.score
                }
            ]
            return {essays}
        },
        addEssayQuestion: (state, action) => {
            state.essays.push({
                question: '',
                correctAnswer: '',
                score: 10
            })
        },
        removeEssayQuestion: (state, action) => {
            const {index} = action.payload
            state.essays.splice(index, 1)
        },
        resetEssayState: () => {
            return {
                essays: [
                    {
                        question: '',
                        correctAnswer: '',
                        score: 10
                    }
                ]
            }
        }
    }
})

export const { setEssayQuestion, setEssayCorrectAnswer, setEssayScore, setWholeESState, resetEssayState, addEssayQuestion, removeEssayQuestion } = essaySlice.actions

export default essaySlice.reducer