import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import SuspenseContent from "./containers/SuspenseContent";
import { Toaster } from "react-hot-toast";
import PrivateLayout from "./components/Layout/PrivateLayout";
import AuthLayout from "./components/Layout/AuthLayout";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const LoginAdmin = lazy(() => import("./pages/LoginAdmin"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    // themeChange(false)
  }, []);

  return (
    <>
      <Router>
        <Toaster toastOptions={{ duration: 2000 }} position="top-right" />

        <Suspense fallback={<SuspenseContent />}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/admin/login" element={<LoginAdmin />} />
            </Route>

            {/* <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/documentation" element={<Documentation />} /> */}

            {/* Place new routes over this */}

            <Route element={<PrivateLayout />}>
              <Route path="/app/*" element={<Layout />} />
            </Route>
            <Route element={<PrivateLayout />}>
              <Route path="/app/admin/*" element={<Layout />} />
            </Route>

            <Route
              path="*"
              element={<Navigate to="/app/dashboard" replace />}
            />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
