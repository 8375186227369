import { createSlice } from '@reduxjs/toolkit'

export const multipleChoiceSlice = createSlice({
    name: 'multipleChoice',
    initialState: {
        // currentAction: 'create',
        multipleChoices: [
            {
                question: '',
                option: [''],
                correctAnswer: '',
                indexCorrectAnswer: null
            }
        ]
    },
    reducers: {
        setMultiChoiceQuestion: (state, action) => {
            const {indexQuestion, value} = action.payload
            state.multipleChoices[indexQuestion].question = value
        },
        setMultiChoiceOptions: (state, action) => {
            const {indexQuestion, indexOption, value} = action.payload
            // if index of the altered selected option equals to index of the correct answer, then update the correct answer too
            if(indexOption === state.multipleChoices[indexQuestion].indexCorrectAnswer) {
                state.multipleChoices[indexQuestion].correctAnswer = value
            }
            state.multipleChoices[indexQuestion].option[indexOption] = value
        },
        setMultiChoiceAnswer: (state, action) => {
            const {value, indexQuestion, indexOption} = action.payload
            // persist the position of the correct answer with it's index
            state.multipleChoices[indexQuestion].indexCorrectAnswer = indexOption
            state.multipleChoices[indexQuestion].correctAnswer = value
        },
        setWholeMCState: (state, action) => {
            const {data} = action.payload
            const multipleChoices = [
                {
                    question: data.question,
                    option: [...data.option],
                    correctAnswer: data.correctAnswer,
                    indexCorrectAnswer: data.option.findIndex((option) => option === data.correctAnswer)
                }
            ]
            return {multipleChoices}
        },
        addMultiChoiceQuestion: (state, action) => {
            state.multipleChoices.push({
                question: '',
                option: [''],
                correctAnswer: ''
            })
        },
        addMultiChoiceOptions: (state, action) => {
            const {indexQuestion} = action.payload
            state.multipleChoices[indexQuestion].option.push('')
        },
        removeMultiChoiceQuestion: (state, action) => {
            const {indexQuestion} = action.payload
            state.multipleChoices.splice(indexQuestion, 1)
        },
        removeMultiChoiceOptions: (state, action) => {
            const {indexQuestion, indexOption} = action.payload
            state.multipleChoices[indexQuestion].option.splice(indexOption, 1)
            // everytime remove the options, reset the answer
            const listOptions = document.querySelectorAll(`[name="answer-mc-${indexQuestion}"]`)
            // loop through the radio input answer and uncheck it
            listOptions.forEach((option) => {
                option.checked = false
            })
            // state.multipleChoices[indexQuestion].correctAnswer = ''
            state.multipleChoices[indexQuestion].indexCorrectAnswer = null
        },
        resetMultiChoiceState: () => {
            return {
                multipleChoices: [
                    {
                        question: '',
                        option: [''],
                        correctAnswer: '',
                        indexCorrectAnswer: null
                    }
                ]
            }
        }
    }
})

export const { setWholeMCState, setMultiChoiceQuestion, setMultiChoiceOptions, setMultiChoiceAnswer, resetMultiChoiceState, addMultiChoiceQuestion, addMultiChoiceOptions, removeMultiChoiceQuestion, removeMultiChoiceOptions } = multipleChoiceSlice.actions

export default multipleChoiceSlice.reducer